const GoKwikIcon = ({ ...props }) => {
  return (
    <svg 
      width={props?.width ? props?.width : '70'} 
      height={props?.height ? props?.height : '20'}
      viewBox="0 0 70 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5037_4953)">
      <path d="M15.9563 9.99993L6.35314 19.6129C5.83762 20.129 5.00439 20.129 4.48886 19.6129L0.386643 15.5065C-0.128881 14.9905 -0.128881 14.1564 0.386643 13.6403L9.98979 4.02734L15.9563 9.99993Z" fill="url(#paint0_linear_5037_4953)"/>
      <path d="M10.6709 8.6818L2.1208 17.2427L0.606201 15.7265L9.15633 7.16766L10.6709 8.6818Z" fill="url(#paint1_linear_5037_4953)"/>
      <path d="M8.41922 14.3985L0.386643 6.35766C-0.128881 5.8416 -0.128881 5.00751 0.386643 4.49146L4.48885 0.38704C5.00438 -0.129013 5.83762 -0.129013 6.35314 0.38704L14.3857 8.42787L8.41922 14.3985Z" fill="url(#paint2_linear_5037_4953)"/>
      <path d="M17.3256 13.9603L11.6788 19.6128C11.1633 20.1289 10.3301 20.1289 9.81456 19.6128L5.71233 15.5064C5.1968 14.9903 5.1968 14.1563 5.71233 13.6402L11.3591 7.98761L17.3256 13.9603Z" fill="url(#paint3_linear_5037_4953)"/>
      <path d="M15.9966 8.6818L7.44651 17.2407L5.93188 15.7265L14.482 7.16766L15.9966 8.6818Z" fill="url(#paint4_linear_5037_4953)"/>
      <path d="M18.7696 7.4856L11.6882 0.396876C11.1686 -0.123177 10.3254 -0.123177 9.8039 0.396876L5.72168 4.4833C5.20215 5.00335 5.20215 5.84744 5.72168 6.36949L12.2137 12.8681C13.9261 14.5823 13.9261 17.3626 12.2137 19.0768L15.3148 15.9725L18.7696 12.5141C20.1564 11.126 20.1564 8.87573 18.7696 7.4856Z" fill="url(#paint5_linear_5037_4953)"/>
      <path d="M26.2684 9.70771H30.3185V13.434C29.4257 14.4162 28.1053 14.9902 26.7473 14.9902C24.1577 14.9902 21.9819 12.91 21.9819 10.4478C21.9819 7.98551 24.1577 5.9173 26.7473 5.9173C28.1053 5.9173 29.4257 6.49136 30.3185 7.46144L28.5211 9.05564C28.0935 8.49358 27.4392 8.16151 26.7473 8.16151C25.5274 8.16151 24.4967 9.20764 24.4967 10.4458C24.4967 11.6959 25.5274 12.742 26.7473 12.742C27.1751 12.742 27.5889 12.614 27.9555 12.384V11.5298H26.2704V9.70771H26.2684Z" fill="#004B8D"/>
      <path d="M31.0854 11.5317C31.0854 9.65547 32.7586 8.08527 34.7571 8.08527C36.7436 8.08527 38.4169 9.65547 38.4169 11.5317C38.4169 13.4079 36.7436 14.99 34.7571 14.99C32.7586 14.99 31.0854 13.4079 31.0854 11.5317ZM36.1544 11.5317C36.1544 10.7655 35.5139 10.1275 34.759 10.1275C33.9924 10.1275 33.3499 10.7655 33.3499 11.5317C33.3499 12.2977 33.9904 12.9478 34.759 12.9478C35.5119 12.9478 36.1544 12.2977 36.1544 11.5317Z" fill="#004B8D"/>
      <path d="M39.3738 6.06934H41.8137V9.52767L44.7187 6.06934H47.5981L44.4053 9.73173L47.8503 14.8363H44.9335L42.7084 11.5179L41.8157 12.514V14.8363H39.3758V6.06934H39.3738Z" fill="#004B8D"/>
      <path d="M47.7007 8.23926H50.0263L50.9939 11.5316L51.9498 8.23926H53.9364L54.904 11.5316L55.8599 8.23926H58.1854L55.9742 14.8359H53.824L52.9431 11.4416L52.0877 14.8359H49.9376L47.7007 8.23926Z" fill="#004B8D"/>
      <path d="M58.8032 6.26117C58.8032 5.59711 59.357 5.01105 60.0606 5.01105C60.7406 5.01105 61.2924 5.59911 61.2924 6.26117C61.2924 6.95125 60.7386 7.53731 60.0606 7.53731C59.357 7.53731 58.8032 6.94925 58.8032 6.26117ZM58.8288 8.23938H61.2549V14.838H58.8288V8.23938Z" fill="#004B8D"/>
      <path d="M69.3409 14.8363H66.6254L65.4308 12.654L64.9027 13.3181V14.8363H62.4766V6.06934H64.9027V10.5618L66.5759 8.23954H69.2914L67.0151 11.0859L69.3409 14.8363Z" fill="#004B8D"/>
      </g>
      <defs>
      <linearGradient id="paint0_linear_5037_4953" x1="0.238" y1="19.7628" x2="6.11362" y2="13.8932" gradientUnits="userSpaceOnUse">
      <stop stop-color="#EDDE5D"/>
      <stop offset="1" stop-color="#F09819"/>
      </linearGradient>
      <linearGradient id="paint1_linear_5037_4953" x1="-1.25726" y1="19.1078" x2="3.50953" y2="14.3459" gradientUnits="userSpaceOnUse">
      <stop stop-color="#EDDE5D"/>
      <stop offset="1" stop-color="#F09819"/>
      </linearGradient>
      <linearGradient id="paint2_linear_5037_4953" x1="-0.929164" y1="-0.930114" x2="12.311" y2="12.2965" gradientUnits="userSpaceOnUse">
      <stop stop-color="#EDCE4D"/>
      <stop offset="1" stop-color="#F09819"/>
      </linearGradient>
      <linearGradient id="paint3_linear_5037_4953" x1="22.7217" y1="2.58742" x2="8.20646" y2="17.0878" gradientUnits="userSpaceOnUse">
      <stop offset="0.1617" stop-color="#000428"/>
      <stop offset="1" stop-color="#004B8D"/>
      </linearGradient>
      <linearGradient id="paint4_linear_5037_4953" x1="14.5863" y1="8.57913" x2="6.5563" y2="16.6008" gradientUnits="userSpaceOnUse">
      <stop offset="0.1617" stop-color="#000428"/>
      <stop offset="1" stop-color="#004B8D"/>
      </linearGradient>
      <linearGradient id="paint5_linear_5037_4953" x1="-15.0045" y1="-17.2462" x2="14.4347" y2="12.1628" gradientUnits="userSpaceOnUse">
      <stop offset="0.1617" stop-color="#000428"/>
      <stop offset="1" stop-color="#004B8D"/>
      </linearGradient>
      <clipPath id="clip0_5037_4953">
      <rect width="70" height="20" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
  
export default GoKwikIcon;