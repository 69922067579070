// Base Imports
import React from 'react'

// Package Imports
import { Formik, Form } from 'formik'

// Component Imports
import FloatingLabelFormField from '@components/utils/ui/FloatingLabelFormField'

// Other Imports
import { findByFieldName } from '@framework/utils/app-util'
import { PAGE_TYPE_LOGIN_MODULE } from '@components/utils/constants'

export interface INewLoginFormProps {
  readonly formId: string
  readonly schema: any
  readonly initialValues?: any
  readonly defaultValues?: any
  readonly formFields: Array<any>
  readonly isOTPSent?: boolean
  readonly mobileNumber?: any
  onSubmit?: any
}

const NewGoKwikLoginForm = React.forwardRef(
  (props: INewLoginFormProps, ref) => {
    const {
      formId,
      schema,
      initialValues = null,
      defaultValues,
      formFields,
      onSubmit = () => {},
      isOTPSent = false,
    } = props
    const initState = defaultValues
      ? Object.keys(defaultValues).length
        ? defaultValues
        : initialValues
      : initialValues

    return (
      <Formik
        //  @ts-ignore
        innerRef={ref}
        validationSchema={schema}
        initialValues={initState}
        onSubmit={(values) => {
          const payload = {
            ...values,
          }
          onSubmit(payload)
        }}
      >
        {(context) => {
          const { handleSubmit } = context

          return (
            <>
              <Form id={formId} onSubmit={handleSubmit}>
                <div className="w-full p-0 pt-0">
                  <div className="w-full mt-1 add-form-section">
                    {(formFields?.length
                      ? Array.from<any>([
                          !isOTPSent
                            ? findByFieldName(formFields, 'mobileNumber')
                            : [],
                        ]).concat(
                          isOTPSent ? [findByFieldName(formFields, 'otp')] : []
                        )
                      : []
                    )?.map((item: any, idx: number) => (
                      <FloatingLabelFormField
                        key={idx}
                        context={context}
                        item={item}
                        formId={formId}
                        // pageType='loginModule'
                        pageType={PAGE_TYPE_LOGIN_MODULE}
                      />
                    ))}
                  </div>
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
    )
  }
)

export default NewGoKwikLoginForm
