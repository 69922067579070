// Base Imports
import React, { FC } from "react";

// Component Imports
import Modal from "../Modal";

// Other Imports

const ErrorView: FC<{ error: any; closeModal(): any }> = ({
    error,
    closeModal,
}: any) => {
    return (
        <Modal className="p-0 relative Z-9999" onClose={closeModal}>
            {error && error?.error_message && (
                <>
                    <div className="flex flex-col w-full">
                        <p className="font-medium text-sm p-3">
                            {error.error_message}
                        </p>
                    </div>
                </>
            )}
        </Modal>
    )
}

export default ErrorView;